import service from '@/utils/request'; // @Tags electric
// @Summary 获取电费渠道/区域配置
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.electric true "获取电费渠道/区域配置"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /config/get [get]

export var getElectricSetting = function getElectricSetting(params) {
  return service({
    url: "/config/get",
    method: 'get',
    params: params
  });
}; // @Tags EquityCoupon
// @Summary 设置渠道/区域设置
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "设置渠道/区域设置"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/coupon [get]

export var setElectricSetting = function setElectricSetting(data) {
  return service({
    url: "/config/set",
    method: 'post',
    data: data
  });
}; // @Tags EquityCoupon
// @Summary 获取电费省市列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "获取电费省市列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /region/pclist [get]

export var setElectricCity = function setElectricCity(params) {
  return service({
    url: "/region/pclist",
    method: 'get',
    params: params
  });
};